import IconQuality from "../../../common/assets/icons/IconQuality.png";
import IconIndividual from "../../../common/assets/icons/IconIndividual.png";
import IconSecurity from "../../../common/assets/icons/IconSecurity.png";
import { FeaturesGroupItem } from "../atoms/FeaturesGroupItem";
import "../styles/FeaturesGroup.css";

export const FeaturesGroup = () => {
  return (
    <div className="row col-12 m_fg_main_con ">
      <FeaturesGroupItem
        icon={IconQuality}
        title="Быстро и Качественно"
        text="Наш наработанный опыт позволяет быстро и качественно решить любые задачи."
      />
      <FeaturesGroupItem
        icon={IconIndividual}
        title="Индивидуальный подход"
        text="Учитываем все пожелания заказчика."
      />
      <FeaturesGroupItem
        icon={IconSecurity}
        title="Надежность"
        text="Более 20 лет на рынке специалисты высокого уровня."
      />
    </div>
  );
};
