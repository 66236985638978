import { useEffect, useRef, useState } from "react";
import { Nav, Tab, Form, Button, Spinner } from "react-bootstrap";
import { mainColor, orderSenderName, destinationEmailName1, destinationEmail1, destinationEmailName2, destinationEmail2 } from "../../../common/config/vars";
import { empty } from "../../../common/functions";
import { querySendMail } from "../../../common/sendMail";
import "../styles/AutomationInputContainer.css"
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { pathMain } from "../../../common/config/paths";
import { AutomationInputContainerInput } from "./AutomationInputContainerInput";

interface IAutomationInputContainer {
  hash: string
}

const products = [
  {id: "1", name: "1C: Бухгалтерия"},
  {id: "2", name: "1С:Зарплата и Управление персоналом"},
  {id: "3", name: "1С: Управление нашей фирмой"},
  {id: "4", name: "1С:Розница"},
  {id: "5", name: "1С:УАТ"},
  {id: "6", name: "1С:ERP"},
  {id: "7", name: "Другое"}
]

export const AutomationInputContainer = ({hash}: IAutomationInputContainer) => {
  const [loading, setLoading] = useState(false)
  const [failMsg, setFailmsg] = useState("")
  const [okMsg, setOkMsg] = useState("")
  const nameRef = useRef(null)
  const history = useHistory()

  const [selProduct, setSelProduct] = useState("1")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [dopMsg, setDopMsg] = useState("")

  useEffect(() => {
    if(hash == "#tab_contact2us_con_name_form" && nameRef != null && nameRef.current != null) {
      //@ts-ignore
      nameRef.current.focus()
      history.replace(pathMain)
    }
     // eslint-disable-next-line
  }, [hash])

  useEffect(() => {
    if(!loading) {
      if(failMsg.length > 0) {
        toast.error(failMsg)
      } else if (okMsg.length > 0) {
        toast.success(okMsg)
      }
      setFailmsg("")
      setOkMsg("")
    }
    // eslint-disable-next-line 
  }, [loading])

  const onSendBtnClicked = () => {
    //@ts-ignore
    let p = products.find((e, i) => e.id = selProduct)
    if(empty(name)) {
      toast.error("Заполните поле \"Имя\"")
      return
    } else if(empty(email)) {
      toast.error("Заполните поле \"Email\"")
      return
    }
    let text = "Новый заказ\n\r" +
    "Имя: " + name + " \n\r"+
    "Почта: " + email + " \n\r"
    if(p != undefined ) text += "Продукт: " + p.name + " \n\r"
    console.log(text)
    querySendMail({destMail: destinationEmail2, destName: destinationEmailName2, senderName: orderSenderName, setLoading: setLoading, setFailMsg: setFailmsg, setOkMsg: setOkMsg, title: "Заказ звонка", text: text})
  }

  const onOrderCallBtnClicked = () => {
    if(empty(name)) {
      toast.error("Заполните поле \"Имя\"")
      return
    } else if(empty(phone)) {
      toast.error("Заполните поле \"Номер телефона\"")
      return
    }
    let text = "Новый заказ\n\r" +
    "Имя: " + name + " \n\r"+
    "Номер: " + phone + " \n\r"
    console.log(text)
    querySendMail({destMail: destinationEmail2, destName: destinationEmailName2, senderName: orderSenderName, setLoading: setLoading, setFailMsg: setFailmsg, setOkMsg: setOkMsg, title: "Заказ звонка", text: text})
  }

  return (
    <div  className="m_aic_main_con">
    <Tab.Container id="tab_contact2us_con1" defaultActiveKey="contact2us">
      <Nav variant="pills" className="flex-row">
        <Nav.Item>
          <Nav.Link eventKey="contact2us" className="m_aic_title">Связаться с нами</Nav.Link>
        </Nav.Item>
        <Nav.Item >
          <Nav.Link eventKey="orderCall" className="m_aic_title">Заказать звонок</Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content className="m_aic_tab_content">
        <Tab.Pane eventKey="contact2us">
          <div className="m_aic_contact2us_con">
            <label style={{textAlign: "left"}}>Ваше имя</label>
            <Form.Control id="tab_contact2us_con_name_form" ref={nameRef} type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Введите ваше имя" className="m_a_aici_contacts_form_inp"/>
         
            <AutomationInputContainerInput title={"Ваш Email"} val={email} setVal={setEmail}  placholder={"Введите ваш Email"}/>

            <label style={{textAlign: "left"}}>Выберите продукт</label>
            <Form.Control as="select" value={selProduct} onChange={e => setSelProduct(e.target.value)}  placeholder="Выберите продукт" style={{marginBottom: "10px"}}>
              {products.map((p, i) => (
                <option key={i} value={p.id} >{p.name}</option>
              ))}
            </Form.Control>

            <Button onClick={onSendBtnClicked} disabled={loading} variant="default" style={{background: mainColor, fontWeight: "bold", color: "#FFFFFF"}}>
              {loading ? <Spinner animation="border" size="sm"/> : "Отправить"}
            </Button>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="orderCall">
          <div className="m_aic_contact2us_con">
            <label style={{textAlign: "left"}}>Ваше имя</label>
            <Form.Control id="tab_order_call_con_name_form" type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Введите ваше имя" className="m_a_aici_contacts_form_inp"/>

            <AutomationInputContainerInput title={"Ваш номер телефона"} val={phone} setVal={setPhone}  placholder={"Введите ваш номер телефона"}/>

            <AutomationInputContainerInput title={"Сообщение"} val={dopMsg} setVal={setDopMsg}  placholder={"Дополнительная информация"}/>

            <Button onClick={onOrderCallBtnClicked} disabled={loading} variant="default" style={{background: mainColor, fontWeight: "bold", color: "#FFFFFF"}}>
              {loading ? <Spinner animation="border" size="sm"/> : "Заказать звонок"}
            </Button>
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
    </div>
  );
}
