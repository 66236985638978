import { contactUsEmail, contactUsTel } from "../../config/vars";
import "./styles/index.css";

const comInfo =
  "© IT компания АБС 2000-" +
  new Date().getFullYear() +
  " г. Все права защищены.";

export const Footer = () => {
  return (
    <footer className="footer_tag">
      <div className="footer_contact_us_con">
        <a href={"tel:" + contactUsTel} className="m_fgi_text_span">
          {contactUsTel}
        </a>{" "}
        <br />
        <a href={"mailto:" + contactUsEmail} className="m_fgi_text_span">
          {contactUsEmail}
        </a>
      </div>
      <span className="footer_com_info">{comInfo}</span>
    </footer>
  );
};
