import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ABSLogo from "../../assets/icons/ABSLogo.png";
import { pathMain } from "../../../common/config/paths";
import { Button } from "react-bootstrap";
import "./styles/index.css";
import { useStore } from "effector-react";
import { $headerNavList } from "./logics/navList";
import { HNavList } from "./molecules/NavList";
import {
  contactUsEmail,
  contactUsTel,
  elemID_TabContact2usConNameForm,
} from "../../config/vars";

// interface IHeader {

// }

export const Header = () => {
  const navList = useStore($headerNavList);
  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);
  const [fixMenu, setFixMenu] = useState("");
  const [fixedMainCon, setFixedMainCon] = useState("header_main_con");

  const handleScroll = () => {
    const position = window.pageYOffset;
    setFixMenu(position > 0 ? "header_toggle_menu_fixed" : "");
    setFixedMainCon(
      position > 0 ? "header_main_con header_main_con_fixed" : "header_main_con"
    );
  };

  const onContact2usClicked = () => {
    setOpenMenu(false);
    history.push(`${pathMain}#${elemID_TabContact2usConNameForm}`);
    //document.getElementById("tab_contact2us_con_name_form")?.focus()
  };

  const onIconCLicked = () => {
    setOpenMenu(false);
    history.push(pathMain);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={fixedMainCon}>
        <div className="row m-auto header_main_sub_con">
          <Button
            onClick={() => setOpenMenu(!openMenu)}
            variant="default"
            className={
              "header_btn_menu " + (openMenu ? "header_btn_menu_active" : "")
            }
          >
            <i></i>
          </Button>
          <div
            className={
              "header_toggle_menu " +
              fixMenu +
              " " +
              (openMenu ? "header_toggle_menu_active" : "")
            }
          >
            <div className="header_toggle_menu_content">
              <HNavList
                setOpenMenu={setOpenMenu}
                noDropdown={true}
                list={navList}
                nClas="header_nav_tel"
              />
              <div className="header_btn_contact_us_con">
                <div className="header_phone_and_mail_con">
                  <a href={"tel:" + contactUsTel} className="m_fgi_text_span">
                    {contactUsTel}
                  </a>{" "}
                  <br />
                  <a
                    href={"mailto:" + contactUsEmail}
                    className="m_fgi_text_span"
                  >
                    {contactUsEmail}
                  </a>
                </div>
                <Button
                  onClick={onContact2usClicked}
                  variant="default"
                  className="header_btn_contact_us"
                >
                  Связаться с нами
                </Button>
                <hr className="header_toggle_menu_hr" />
              </div>
              <div></div>
            </div>
          </div>
          <div className="header_logo_con">
            <img
              src={ABSLogo}
              style={{ width: "100px", cursor: "pointer" }}
              onClick={onIconCLicked}
              alt="ООО АБС"
            ></img>
          </div>
          <div className={" m-auto header_nav_con header_nav_con_min1100 "}>
            <HNavList setOpenMenu={setOpenMenu} list={navList} />
          </div>
          <div className="header_btn_contact_us_con">
            <div className="header_phone_and_mail_con">
              <a href={"tel:" + contactUsTel} className="m_fgi_text_span">
                {contactUsTel}
              </a>
              <br />
              <a href={"mailto:" + contactUsEmail} className="m_fgi_text_span">
                {contactUsEmail}
              </a>
            </div>
            <Button
              onClick={onContact2usClicked}
              variant="default"
              className="header_btn_contact_us"
            >
              Связаться с нами
            </Button>
          </div>
          {/* <div className= {" m-auto header_nav_con header_nav_con_max1100"}  >
          <HNavList list={navList} />
        </div> */}
        </div>
      </header>
    </>
  );
};
