import "../styles/AutoListItem.css";

interface IAutoListItem {
  title: string;
  icon: string;
  list: { id: string; name: string }[];
}

export const AutoListItem = ({ title, icon, list }: IAutoListItem) => {
  return (
    <div className="a_ali_main_con">
      <div className="a_ali_img_con">
        <img src={icon} className="a_ali_img" alt=""></img>
      </div>
      <div className="a_ali_auto_list_con">
        <h3 className="a_ali_auto_list_title">{title}</h3>
        <ul className="a_ali_ul">
          {list.map((e, i) => (
            <li key={i} value={e.id} className="a_ali_li">
              {e.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
