import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
   <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>, 
  document.getElementById('root')
);

reportWebVitals();
