import { useEffect } from "react";
import {
  namePathMain,
  namePathConvoy,
  pathMain,
  pathConvoy,
} from "../../common/config/paths";
import { TopPathWay } from "../../common/ui/TopPathWay";
import { ConvoyProductList } from "./organoids/ConvoyList";
import "./styles/ForConvayIndex.css";

const aboutConvoy = (
  //@ts-ignore
  <p style={{ textAlign: "justify" }}>
    <strong>Наша компания оказывает регулярную и оперативную поддержку</strong>{" "}
    пользователей 1С по единой, разработанной фирмой 1 "С" технологии
    сопровождения.
    <br />
    Любая поддержка и услуга продоставляются только по договору
    информационно-технологического сопровождения.
    <br />
    <br />
    <strong>ИТС (Информационно технологичесое сопровождение)</strong> - это
    комплекс услуг и полезных сервисов, гарантирующий актуальность,
    работоспособность программы, круглосуточную поддержу, удобство работы в 1С,
    а также доступ к десятку справочников.
  </p>
);

export const Convoy = () => {
  const topWay = [
    { name: namePathMain, path: pathMain },
    { name: namePathConvoy, path: pathConvoy },
  ];

  useEffect(() => {
    document.title = namePathConvoy;
  }, []);

  return (
    <>
      <div className="page_main_con">
        <div style={{ width: "100%", height: "100%", padding: "20px" }}>
          <TopPathWay list={topWay} />
          <p className="page_title_products gradient1">{namePathConvoy}</p>

          <div className="idx_cac_about_con">{aboutConvoy}</div>
          <ConvoyProductList />
        </div>
      </div>
    </>
  );
};
