// import IconAuto1 from "../../../common/assets/icons/IconAuto1.png";
// import IconClient1 from "../../../common/assets/icons/testIcons/client1.png";
// import IconClient2 from "../../../common/assets/icons/testIcons/client2.png";
// import IconClient3 from "../../../common/assets/icons/testIcons/client3.png";
// import IconClient4 from "../../../common/assets/icons/testIcons/client4.png";
// import IconClient5 from "../../../common/assets/icons/testIcons/client5.png";
// import IconClient6 from "../../../common/assets/icons/testIcons/client6.png";
// import IconClient7 from "../../../common/assets/icons/testIcons/client7.png";
// import IconClient8 from "../../../common/assets/icons/testIcons/client8.png";
// import IconClient9 from "../../../common/assets/icons/testIcons/client9.png";
// import IconClient10 from "../../../common/assets/icons/testIcons/client10.png";
// import IconClient11 from "../../../common/assets/icons/testIcons/client11.png";
// import IconClient12 from "../../../common/assets/icons/testIcons/client12.png";
// import IconClient13 from "../../../common/assets/icons/testIcons/client13.png";
// import IconClient14 from "../../../common/assets/icons/testIcons/client14.png";
// import IconClient16 from "../../../common/assets/icons/testIcons/client16.png";
// import IconClient17 from "../../../common/assets/icons/testIcons/client17.png";
// import IconClient20 from "../../../common/assets/icons/testIcons/client20.png";
// import IconClient21 from "../../../common/assets/icons/testIcons/client21.png";
// import { AutoListItem } from "../atoms/AutoListItem";
// import { AutomationInputContainer } from "../atoms/AutomationInputContainer";
// import { FeedbackSlider } from "./FeedbackSlider";

import { OurClientsBlock } from "./OurClientsBlock";

import { FeedbackGroupItem } from "./FeedbackGroupItem";
import "../styles/FeedbackGroup.css";
import { getData } from "./FeedbackDataLoad";

import "../styles/FeedbackSlider.css";
import { Carousel } from "react-bootstrap";
import { ChevronLeft, ChevronRight, Slash } from "react-bootstrap-icons";
import { useState } from "react";
import { IMAGES_URL } from "../../../common/config/vars";

interface IAutomationGroup {
  hash: string;
}

const data: JSX.Element[] = [];

// SliderData.forEach((e, i) => {
//    //data.push(<ImgSliderCard content={<FeedbackGroupItem key={i} icon={e.icon} feedback={e.feedback} fio={e.fio} position={e.position} />} active={false} acticeClass="" cClass="m_feedg_cards_slider" key={i}/>)
//    console.log(e)
// })

export const FeedbackGroup = ({ hash }: IAutomationGroup) => {
  const [currKey, setCurrKey] = useState(1);
  const [SliderData, changeSliderData] = useState([]);

  if (SliderData.length === 0) {
    //@ts-ignore
    getData().then((data) => changeSliderData(data));
  }

  function onSlide(key: number, direction: "right" | "left") {
    setCurrKey(key + 1);
  }
  return (
      <div className="row col-12 m_feedg_main_con " aria-hidden="true">
        <OurClientsBlock />
        <h1 className="m_feedg_clients_trust_us_con">
          Наши заказчики доверяют нам
        </h1>
        {/* <div className="m_feedg_main_sub_con">
        <ImgSlider2 data={data} classMainCon="m_feedg_slider_main_con" classSection="m_feedg_slider_sec"  hidePagination={false}/>
      </div> */}
        <div style={{ width: "100%" }}>
          <div style={{ margin: "auto", maxWidth: "800px" }}>
            <Carousel
              pause={"hover"}
              indicators={false}
              onSlide={onSlide}
              aria-orientation="horizontal"
              className="feedback_carousel"
              prevIcon={<ChevronLeft className="slide_btn_icon" />}
              nextIcon={<ChevronRight className="slide_btn_icon" />}
            >
              {
                //@ts-ignore
                SliderData.map((e:any, i:any) => (
                  <Carousel.Item key={i}>
                    {/* {e.feedback} */}

                    <FeedbackGroupItem
                      key={i}
                      icon={
                        //@ts-ignore
                        IMAGES_URL + e.LogoPath
                      }
                      feedback={e.Description}
                      fio={e.fio}
                      position={e.position}
                      comName={e.Name}
                    />
                  </Carousel.Item>
                ))
              }
            </Carousel>

            <div style={{ margin: "auto" }}>
              {currKey}
              <Slash
                style={{ fontSize: "30px", margin: "auto", padding: "0" }}
              />
              {
                //@ts-ignore
                SliderData.length
              }
            </div>
          </div>
        </div>
      </div>
  );
};
