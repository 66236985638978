import "../styles/FeaturesGroupItem.css";

interface IFeaturesGroupItem {
  icon: string;
  title: string;
  text: string;
}

export const FeaturesGroupItem = ({
  icon,
  title,
  text,
}: IFeaturesGroupItem) => {
  return (
    <div className="m_featuresgi_main_con">
      <img src={icon} className="m_fgi_img" alt=""></img>
      <h2 className="m_fgi_title_span">{title}</h2>
      <p className="m_fgi_text_span">{text}</p>
    </div>
  );
};
