import React from "react";
import IconPhone from "../../../common/assets/icons/IconPhone.png";
import IconLocation from "../../../common/assets/icons/IconLocation.png";
import IconLocationMap from "../../../common/assets/icons/IconLocationMap.png";
import IconMessage from "../../../common/assets/icons/IconMessage.png";
import { Info } from "./Info";
import "../styles/InfoBlock.css";

export const InfoBlock = () => {
  const contactsInfo = [
    {
      icon: IconLocation,
      text: "660135 г. Красноярск ул. Взлётная, 7а, офис 164",
    },
    { icon: IconPhone, text: "+ 7 (391) 204-08-25" },
    { icon: IconMessage, text: "office@kras-abs.ru" },
  ];

  const commercialInfo = [
    { icon: IconPhone, text: "+ 7 (391) 292-97-23" },
    { icon: IconMessage, text: "opr@kras-abs.ru" },
  ];

  return (
    <div className="m_ib_main_con">
      <p className="m_ib_contacts_title_top">{"Контактная информация:"}</p>
      <div className="m_ib_info_con">
        <div className="m_ib_info_sub_con">
          <Info
            title={"Контактная информация:"}
            list={contactsInfo}
            titleClass="m_ib_contacts_title"
          />
          <Info
            title={"Коммерческий отдел:"}
            list={commercialInfo}
            titleClass="m_ib_commercial_title"
          />
        </div>
      </div>
      <div className="m_ib_icon_location_map_con">
        <img src={IconLocationMap} alt="" className="m_ib_icon_location_map" />
      </div>
    </div>
  );
};
